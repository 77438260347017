"use strict";

function Accordion() {
  $(".faq-question").click(function () {
    var question = $(this);
    var answer = question.next(".faq-answer");
    if (answer.is(":visible")) {
      question.removeClass("active");
      answer.slideUp();
    } else {
      $(".faq-question").removeClass("active");
      $(".faq-answer:visible").slideUp();
      question.addClass("active");
      answer.slideDown();
    }
  });
}
$(document).ready(function ($) {
  var current_page = 1;
  var tab_index = 1;
  var post_id = $('#page-id').val();
  function loadCards(page, tabIndex) {
    $.ajax({
      url: myAjax.ajaxurl,
      method: 'POST',
      data: {
        action: 'load_cards_' + tabIndex,
        page: page,
        tab_index: tabIndex,
        post_id: post_id
      },
      beforeSend: function beforeSend() {},
      success: function success(response) {
        if (response.success) {
          $('#tab-' + tabIndex + ' .cards-wrapper-inner').html(response.data.cards);
          $('#tab-' + tabIndex + ' .pagination').html(response.data.pagination);
          current_page = page;
          updatePaginationState(page, tabIndex);
        } else {
          console.log('Ошибка: ', response.data.message);
        }
      },
      error: function error(xhr, status, _error) {
        console.log('Ошибка AJAX: ', _error);
      }
    });
  }
  $(document).on('click', '.pagination .page-number', function (e) {
    e.preventDefault();
    var page = $(this).data('page');
    var tabIndex = $(this).closest('.cards-wrapper').attr('id').split('-')[1];
    loadCards(page, tabIndex);
    $('html, body').animate({
      scrollTop: $('.cards-wrapper.active').offset().top - 200
    }, 500);
  });
  $(document).on('click', '.prev-page', function (e) {
    e.preventDefault();
    var tabIndex = $(this).closest('.cards-wrapper').attr('id').split('-')[1];
    if (current_page > 1) {
      loadCards(current_page - 1, tabIndex);
    }
    $('html, body').animate({
      scrollTop: $('.cards-wrapper.active').offset().top - 200
    }, 500);
  });
  $(document).on('click', '.next-page', function (e) {
    e.preventDefault();
    var tabIndex = $(this).closest('.cards-wrapper').attr('id').split('-')[1];
    loadCards(current_page + 1, tabIndex);
    $('html, body').animate({
      scrollTop: $('.cards-wrapper.active').offset().top - 200
    }, 500);
  });
  function updatePaginationState(page, tabIndex) {
    if (page === 1) {
      $('#tab-' + tabIndex + ' .prev-page').addClass('disabled');
    } else {
      $('#tab-' + tabIndex + ' .prev-page').removeClass('disabled');
    }
    var total_pages = $('#tab-' + tabIndex + ' .pagination').data('total-pages');
    if (page === total_pages) {
      $('#tab-' + tabIndex + ' .next-page').addClass('disabled');
    } else {
      $('#tab-' + tabIndex + ' .next-page').removeClass('disabled');
    }
  }

  // $('.cards-tabs .h2').on('click', function() {
  //     let tabId = $(this).data('tab');
  //
  //
  //     $('.cards-tabs .h2').removeClass('active');
  //
  //
  //     $(this).addClass('active');
  //
  //
  //     $('.cards-wrapper').removeClass('active');
  //
  //
  //     let $targetTab = $('#' + tabId);
  //     if ($targetTab.length) {
  //         $targetTab.addClass('active');
  //     }
  //
  //
  //     loadCards(current_page, tab_index);
  // });

  $('.cards-tabs .h2').on('click', function () {
    var tabId = $(this).data('tab');

    // Проверяем, если переключаемся на tab-2
    if (tabId === 'tab-2') {
      var _$targetTab = $('#' + tabId);
      if (_$targetTab.hasClass('disable')) {
        return;
      }
    }

    // Обычное переключение табов
    $('.cards-tabs .h2').removeClass('active');
    $(this).addClass('active');
    $('.cards-wrapper').removeClass('active');
    var $targetTab = $('#' + tabId);
    if ($targetTab.length) {
      $targetTab.addClass('active');
    }

    // Вызываем функцию загрузки карточек
    loadCards(current_page, tab_index);
  });

  // loadCards(current_page, tab_index);
});

function header() {
  menuDropdownsHandler();
  mobileMenuToggle();
  toTop();
  popupInit();
}
function menuDropdownsHandler() {
  if (window.innerWidth > 990) {
    var dropdownItems = document.querySelectorAll('.header-dt-menu .menu-item');
    var firstLevelMenuItems = document.querySelectorAll('.header-dt-menu > .menu-item');
    if (dropdownItems.length !== 0) {
      dropdownItems.forEach(function (dropdown) {
        dropdown.addEventListener('mouseenter', openSubMenu);
      });
    }
    if (firstLevelMenuItems.length !== 0) {
      firstLevelMenuItems.forEach(function (menuItem) {
        menuItem.addEventListener('mouseleave', closeSubMenu);
      });
    }
  } else {
    var _dropdownItems = document.querySelectorAll('.header-dt-menu .menu-item-has-children');
    if (_dropdownItems.length !== 0) {
      _dropdownItems.forEach(function (dropdownItem) {
        dropdownItem.addEventListener('click', function (event) {
          var parentMenuItem = event.target.closest('.menu-item-has-children');
          var subMenu = parentMenuItem.querySelector('.sub-menu');
          if (subMenu && event.target.classList.contains('menu-item-has-children')) {
            subMenu.classList.toggle('visible');
            parentMenuItem.classList.toggle('sub-menu-is-visible');
          }
        });
      });
    }
  }
}
function openSubMenu(event) {
  var subMenu = event.target.querySelector('.sub-menu');
  if (subMenu) {
    subMenu.classList.add('visible');
  }
  event.stopPropagation();
}
function closeSubMenu(event) {
  var subMenus = event.target.querySelectorAll('.sub-menu');
  if (subMenus.length !== 0) {
    subMenus.forEach(function (subMenu) {
      subMenu.classList.remove('visible');
    });
  }
  event.stopPropagation();
}
function mobileMenuToggle() {
  var mobMenuToggle = document.querySelector('.mobile-menu-toggle');
  var headerMobNav = document.querySelector('.header-nav-wrapper');
  if (mobMenuToggle) {
    mobMenuToggle.addEventListener('click', function (e) {
      $(mobMenuToggle).toggleClass('open');
      $(headerMobNav).toggleClass('open');
      document.body.classList.toggle('no-scroll');
    });
  }
}
function toTop() {
  $(window).scroll(function () {
    if ($(this).scrollTop() > 150) {
      $('.totop').addClass('active');
    } else {
      $('.totop').removeClass('active');
    }
  });
  $('.totop').click(function () {
    $('html, body').animate({
      scrollTop: 0
    }, 500);
  });
}
$(document).ready(function () {
  // Получите исходное положение header
  var stickyOffset = $('.site-header').offset().top;
  $(window).scroll(function () {
    if ($(window).scrollTop() > stickyOffset) {
      $('.site-header').addClass('fixed');
      $('.main').addClass('fixed');
    } else {
      $('.site-header').removeClass('fixed');
      $('.main').removeClass('fixed');
    }
  });
});
function popupInit() {
  //popup form

  var close = $(".close-popup");
  var formPopup = $("#popup-form");
  var thanksPopup = $("#popup-thanks");
  var emailPopup = $("#popup-subscribe");
  function openContactPopup(button) {
    if (!document.body.classList.contains('error404')) {
      var cardItem = button.closest('.cards-item');
      var serviceTitle = cardItem.querySelector('h2').innerText;
      var inputField = document.querySelector('input[name="services-input"]');
      inputField.value = serviceTitle;
    }
    formPopup.addClass('active');
    formPopup.find('.popup-inner').addClass('active');
  }
  $(document).on('click', '.error404 section.section-404 button.btn', function (event) {
    var target = event.target;
    openContactPopup(target);
  });
  $(document).on('click', '.cards-item button.btn', function (event) {
    var target = event.target;
    openContactPopup(target);
  });
  $('.cards-tabs .h2').on('click', function () {
    var tabId = $(this).data('tab');
    if (tabId === 'tab-2') {
      var $targetTab = $('#' + tabId);
      if ($targetTab.hasClass('disable')) {
        emailPopup.addClass('active');
        emailPopup.find('.popup-inner').addClass('active');
        isEmailPopup = true;
      }
    }
  });
  close.click(function () {
    formPopup.removeClass('active');
    thanksPopup.removeClass('active');
    emailPopup.removeClass('active');
  });
  //
  // const isTouch = () => 'ontouchstart' in window || window.DocumentTouch && document instanceof window.DocumentTouch || navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0;
  //
  // $(document).mouseup(function(e) {
  //   let popup = $('.popup-inner.active');
  //   console.log('df')
  //   console.log('popup: ', popup);
  //   if(!isTouch()) {
  //     if (isOpen && !popup.is(e.target) && popup.has(e.target).length === 0) {
  //       popup.parent().parent().removeClass('active')
  //       popup.removeClass('active');
  //       isOpen = false;
  //       isThanksPopupOpen = false;
  //       isEmailPopup = false;
  //     }
  //   }
  // });

  $(document).mouseup(function (e) {
    var activePopupInner = $('.popup-inner.active');
    if (activePopupInner.length > 0 && !activePopupInner.is(e.target) && activePopupInner.has(e.target).length === 0) {
      $('.overlay-popup').removeClass('active');
      $('.popup-inner').removeClass('active');
    }
  });
}
document.addEventListener('DOMContentLoaded', function () {
  var isEnLang = window.location.pathname.includes('/en/');
  var formPopup = $("#popup-form");
  var thanksPopup = $("#popup-thanks");
  var emailPopup = $("#popup-subscribe");
  var isWPCF7FormValidated = false;
  $('.wpcf7-form [type="submit"]').click(function (event) {
    if (isWPCF7FormValidated) {
      isWPCF7FormValidated = false;
      return;
    }
    event.preventDefault();
    var contactInfo = $('.contact-info').val().trim();
    var minLengthPhone = 9;
    var minLengthEmail = 5;
    var phoneRegex = /^\+?\d+$/;
    var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (phoneRegex.test(contactInfo) && contactInfo.length > minLengthPhone || emailRegex.test(contactInfo) || !contactInfo.length) {
      // if is valid
      var testForm = $('.test-form');
      testForm.val('valid');
      //   $('.wpcf7-submit').prop('disabled', true);
      var errorDiv = document.querySelector('.form-error');
      if (errorDiv) {
        errorDiv.style.display = 'none';
      }
      isWPCF7FormValidated = true;
      event.target.click();
    } else {
      var _errorDiv = document.querySelector('.form-error');
      if (_errorDiv) {
        _errorDiv.textContent = isEnLang ? 'Please enter a valid phone number or email' : 'Будь ласка, введіть правильний номер телефону або email';
        _errorDiv.style.display = 'block';
        if (contactInfo === "") {
          _errorDiv.style.display = 'none';
        }
      }
    }
  });

  //popup form
  document.addEventListener('wpcf7mailsent', function (event) {
    formPopup.removeClass("active");
    emailPopup.removeClass("active");
    thanksPopup.addClass('active');
    thanksPopup.find('.popup-inner').addClass('active');
  }, false);
  if (window.innerWidth < 990) {
    // Функция для переключения класса active
    var toggleSocials = function toggleSocials(e) {
      e.stopPropagation(); // Останавливаем всплытие события, чтобы не запускался родительский обработчик
      socialsBtn.classList.toggle('active');
    }; // Обработчик клика по кнопке
    // Получаем кнопку и элементы социальных кнопок
    var socialsBtn = document.querySelector('.socials-btn');
    var socialButtons = document.querySelectorAll('.social');
    socialsBtn.addEventListener('click', toggleSocials);

    // Закрытие кнопок социальных сетей, если клик был вне кнопки
    document.addEventListener('click', function (e) {
      // Если клик был вне кнопки или социальных кнопок, убираем класс active
      if (!socialsBtn.contains(e.target) && !e.target.closest('.social')) {
        socialsBtn.classList.remove('active');
      }
    });

    // Предотвращаем закрытие при клике по кнопке социальных сетей
    socialButtons.forEach(function (button) {
      button.addEventListener('click', function (e) {
        e.stopPropagation(); // Останавливаем всплытие события для кнопок социальных сетей
      });
    });
  }
});

var isLoaded = false;
window.addEventListener('load', function () {
  if (isLoaded) return;
  isLoaded = true;
  header();
  slidersHandler();
  Accordion();
});

// ---------------  SLIDERS --------------------

function slidersHandler() {
  HeroSlider();
  ReviewsSlider();
  BlogSlider();
  CertificationSlider();
}
function HeroSlider() {
  var heroSlider = $('.hero-slider');
  heroSlider.slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    loop: true,
    prevArrow: '<button type="button" class="slick-prev arrow"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">\n' + '<path d="M24.0117 32.4774L13.5322 22.1859C13.1774 21.8643 13 21.469 13 21C13 20.531 13.1774 20.1223 13.5322 19.7739L24.0117 9.48241C24.3392 9.1608 24.7485 9 25.2398 9C25.731 9 26.1404 9.1608 26.4678 9.48241C26.8226 9.83082 27 10.2395 27 10.7085C27 11.1776 26.8226 11.5729 26.4678 11.8945L17.2164 20.9799L26.4678 30.0653C26.8226 30.4137 27 30.8224 27 31.2915C27 31.7605 26.8226 32.1558 26.4678 32.4774C26.1404 32.8258 25.731 33 25.2398 33C24.7485 33 24.3392 32.8258 24.0117 32.4774Z" fill="#00B0FF"/>\n' + '</svg>\n</button>',
    nextArrow: '<button type="button" class="slick-next arrow"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">\n' + '<path d="M15.9883 32.4774L26.4678 22.1859C26.8226 21.8643 27 21.469 27 21C27 20.531 26.8226 20.1223 26.4678 19.7739L15.9883 9.48241C15.6608 9.1608 15.2515 9 14.7602 9C14.269 9 13.8596 9.1608 13.5322 9.48241C13.1774 9.83082 13 10.2395 13 10.7085C13 11.1776 13.1774 11.5729 13.5322 11.8945L22.7836 20.9799L13.5322 30.0653C13.1774 30.4137 13 30.8224 13 31.2915C13 31.7605 13.1774 32.1558 13.5322 32.4774C13.8596 32.8258 14.269 33 14.7602 33C15.2515 33 15.6608 32.8258 15.9883 32.4774Z" fill="#00B0FF"/>\n' + '</svg>\n</button>',
    dots: true,
    autoplay: true,
    responsive: [{
      breakpoint: 1025,
      settings: {
        dots: false,
        arrows: false
      }
    }]
  });
}
function ReviewsSlider() {
  var reviewsSlider = $('.reviews-slider');
  reviewsSlider.slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    loop: true,
    prevArrow: '<button type="button" class="slick-prev arrow"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">\n' + '<path d="M24.0117 32.4774L13.5322 22.1859C13.1774 21.8643 13 21.469 13 21C13 20.531 13.1774 20.1223 13.5322 19.7739L24.0117 9.48241C24.3392 9.1608 24.7485 9 25.2398 9C25.731 9 26.1404 9.1608 26.4678 9.48241C26.8226 9.83082 27 10.2395 27 10.7085C27 11.1776 26.8226 11.5729 26.4678 11.8945L17.2164 20.9799L26.4678 30.0653C26.8226 30.4137 27 30.8224 27 31.2915C27 31.7605 26.8226 32.1558 26.4678 32.4774C26.1404 32.8258 25.731 33 25.2398 33C24.7485 33 24.3392 32.8258 24.0117 32.4774Z" fill="#00B0FF"/>\n' + '</svg>\n</button>',
    nextArrow: '<button type="button" class="slick-next arrow"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">\n' + '<path d="M15.9883 32.4774L26.4678 22.1859C26.8226 21.8643 27 21.469 27 21C27 20.531 26.8226 20.1223 26.4678 19.7739L15.9883 9.48241C15.6608 9.1608 15.2515 9 14.7602 9C14.269 9 13.8596 9.1608 13.5322 9.48241C13.1774 9.83082 13 10.2395 13 10.7085C13 11.1776 13.1774 11.5729 13.5322 11.8945L22.7836 20.9799L13.5322 30.0653C13.1774 30.4137 13 30.8224 13 31.2915C13 31.7605 13.1774 32.1558 13.5322 32.4774C13.8596 32.8258 14.269 33 14.7602 33C15.2515 33 15.6608 32.8258 15.9883 32.4774Z" fill="#00B0FF"/>\n' + '</svg>\n</button>',
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        adaptiveHeight: true
      }
    }]
  });
}

// if (window.innerWidth > 990) {
//
// }
function BlogSlider() {
  var reviewsSlider = $('.blog-slider');
  function initSlider() {
    if (!reviewsSlider.hasClass('slick-initialized') && window.innerWidth > 990) {
      reviewsSlider.slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        loop: true,
        prevArrow: '<button type="button" class="slick-prev arrow"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">\n' + '<path d="M24.0117 32.4774L13.5322 22.1859C13.1774 21.8643 13 21.469 13 21C13 20.531 13.1774 20.1223 13.5322 19.7739L24.0117 9.48241C24.3392 9.1608 24.7485 9 25.2398 9C25.731 9 26.1404 9.1608 26.4678 9.48241C26.8226 9.83082 27 10.2395 27 10.7085C27 11.1776 26.8226 11.5729 26.4678 11.8945L17.2164 20.9799L26.4678 30.0653C26.8226 30.4137 27 30.8224 27 31.2915C27 31.7605 26.8226 32.1558 26.4678 32.4774C26.1404 32.8258 25.731 33 25.2398 33C24.7485 33 24.3392 32.8258 24.0117 32.4774Z" fill="#00B0FF"/>\n' + '</svg>\n</button>',
        nextArrow: '<button type="button" class="slick-next arrow"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">\n' + '<path d="M15.9883 32.4774L26.4678 22.1859C26.8226 21.8643 27 21.469 27 21C27 20.531 26.8226 20.1223 26.4678 19.7739L15.9883 9.48241C15.6608 9.1608 15.2515 9 14.7602 9C14.269 9 13.8596 9.1608 13.5322 9.48241C13.1774 9.83082 13 10.2395 13 10.7085C13 11.1776 13.1774 11.5729 13.5322 11.8945L22.7836 20.9799L13.5322 30.0653C13.1774 30.4137 13 30.8224 13 31.2915C13 31.7605 13.1774 32.1558 13.5322 32.4774C13.8596 32.8258 14.269 33 14.7602 33C15.2515 33 15.6608 32.8258 15.9883 32.4774Z" fill="#00B0FF"/>\n' + '</svg>\n</button>',
        responsive: [{
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            adaptiveHeight: true
          }
        }]
      });
    }
  }
  function destroySlider() {
    if (reviewsSlider.hasClass('slick-initialized') && window.innerWidth <= 990) {
      reviewsSlider.slick('unslick');
    }
  }
  initSlider();
  $(window).on('resize', function () {
    destroySlider();
    initSlider();
  });
}
function CertificationSlider() {
  var reviewsSlider = $('.certification-slider');
  reviewsSlider.slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    loop: true,
    prevArrow: '<button type="button" class="slick-prev arrow"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">\n' + '<path d="M24.0117 32.4774L13.5322 22.1859C13.1774 21.8643 13 21.469 13 21C13 20.531 13.1774 20.1223 13.5322 19.7739L24.0117 9.48241C24.3392 9.1608 24.7485 9 25.2398 9C25.731 9 26.1404 9.1608 26.4678 9.48241C26.8226 9.83082 27 10.2395 27 10.7085C27 11.1776 26.8226 11.5729 26.4678 11.8945L17.2164 20.9799L26.4678 30.0653C26.8226 30.4137 27 30.8224 27 31.2915C27 31.7605 26.8226 32.1558 26.4678 32.4774C26.1404 32.8258 25.731 33 25.2398 33C24.7485 33 24.3392 32.8258 24.0117 32.4774Z" fill="#00B0FF"/>\n' + '</svg>\n</button>',
    nextArrow: '<button type="button" class="slick-next arrow"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">\n' + '<path d="M15.9883 32.4774L26.4678 22.1859C26.8226 21.8643 27 21.469 27 21C27 20.531 26.8226 20.1223 26.4678 19.7739L15.9883 9.48241C15.6608 9.1608 15.2515 9 14.7602 9C14.269 9 13.8596 9.1608 13.5322 9.48241C13.1774 9.83082 13 10.2395 13 10.7085C13 11.1776 13.1774 11.5729 13.5322 11.8945L22.7836 20.9799L13.5322 30.0653C13.1774 30.4137 13 30.8224 13 31.2915C13 31.7605 13.1774 32.1558 13.5322 32.4774C13.8596 32.8258 14.269 33 14.7602 33C15.2515 33 15.6608 32.8258 15.9883 32.4774Z" fill="#00B0FF"/>\n' + '</svg>\n</button>',
    dots: true,
    responsive: [{
      breakpoint: 990,
      settings: {
        slidesToShow: 2,
        adaptiveHeight: true
      }
    }, {
      breakpoint: 576,
      settings: {
        slidesToShow: 1
      }
    }]
  });
}